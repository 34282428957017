<template>
    <div class="menu-body">
        <Loader v-if="showLoader" />
        <Breadcrumb />
        <div class="main-con">
            <div class="mat-info-con even-space-vertical-align">
                <div class="plant-dd" style="width: 100%; display: flex">
                    <div class="plant-dd">
                        <label for="committed_year" class="form-label select-lable lable-text"> Committed Year </label>
                        <select class="form-control select-text select-text-pd cust-st-icon" id="committedYear"
                            v-model="committedYr" @change="filterHandler()">
                            <option v-for="year in committedYears" v-bind:key="year" :value="year">
                                {{ year }}
                            </option>
                        </select>
                    </div>
                    <div class="plant-dd" style="margin-left: 10px">
                        <label for="month" class="form-label select-lable lable-text"> Month </label>
                        <select class="form-control select-text select-text-pd cust-st-icon" id="month"
                            v-model="selectedMonth" @change="filterHandler()">
                            <option v-for="eachMonth in monthsList" v-bind:key="eachMonth.monthId"
                                :value="eachMonth.monthId">
                                {{ eachMonth.monthName }}
                            </option>
                        </select>
                    </div>
                    <div class="plant-dd" style="margin-left: 10px">
                        <label for="plant" class="form-label select-lable lable-text"> Plant </label>
                        <select class="form-control select-text select-text-pd cust-st-icon" id="plantId"
                            :value="plantSelectedInAsmtHome" @change="plantChanged($event)">
                            <option v-for="plant in plantList" v-bind:key="plant.plantId" :value="plant.plantId">
                                {{ plant.plantName + ", " + plant.regionCode }}
                            </option>
                        </select>
                    </div>
                    <div class="cml-by">
                        <span><img alt="warn" src="@/assets/complete-by.svg" /></span>
                        <span class="cmp-by-txt"> Data entries for {{ willFreezeFor }} will freeze on {{ willFreezeOn }} EOD
                        </span>
                    </div>
                </div>
            </div>
            <div class="top-ribbon" v-if="machineDetail && machineDetail.length !== 0">
                <div class="inner-top-ribbon" v-if="ribbonConstraint.primary">
                    <div class="target-card-lable">1<sup>st</sup> Constraint</div>
                    <div class="target-card-value">{{ ribbonConstraint.primary }}</div>
                </div>
                <div class="inner-top-ribbon" v-if="ribbonConstraint.secondary">
                    <div class="target-card-lable">2<sup>nd</sup> Constraint</div>
                    <div class="target-card-value">{{ ribbonConstraint.secondary }}</div>
                </div>
                <div class="inner-top-ribbon" v-if="ribbonConstraint.tertiary">
                    <div class="target-card-lable">3<sup>rd</sup> Constraint</div>
                    <div class="target-card-value">{{ ribbonConstraint.tertiary }}</div>
                </div>
            </div>
            <div v-if="machineDetail && machineDetail.length !== 0"
                style="display: flex; justify-content: right; padding: 1rem 2rem 0 2rem">
                <div class="edit-button-div">
                    <b-button class="btn ed-st-btn" style="border: transparent; background: transparent; color: #274ba7"
                        pill variant="outline-primary" v-if="editingMode" @click="stopEditing()">
                        Cancel
                    </b-button>
                    <b-button class="asmt-bt button-sm" style="padding: 0.25rem 1rem; margin-left: 1rem" pill
                        variant="outline-primary" @click="onViewBtn()" v-if="!editingMode">
                        View Machines
                    </b-button>
                    <b-button class="asmt-bt button-sm" style="padding: 0.25rem 1rem; margin-left: 1rem" pill
                        variant="outline-primary" @click="editingMode = true"
                        v-if="!editingMode && ($oidc.userProfile.roles.includes(roles.PLANTPILLARLEADER) || $oidc.userProfile.roles.includes(roles.PLANTPOLEADER) || $oidc.userProfile.roles.includes(roles.GLOBALPILLARLEADER))"
                        :disabled="shouldUpdateDisable">
                        Update
                    </b-button>
                    <b-button pill variant="outline-primary" style="padding: 0.25rem 1rem; margin-left: 1rem"
                        class="btn cancel-save-btn button-sm" @click="saveManualData()"
                        v-if="editingMode && ($oidc.userProfile.roles.includes(roles.PLANTPILLARLEADER) || $oidc.userProfile.roles.includes(roles.PLANTPOLEADER) || $oidc.userProfile.roles.includes(roles.GLOBALPILLARLEADER))"
                        :disabled="shouldUpdateDisable">
                        Save
                    </b-button>
                </div>
            </div>
            <div style="display: flex; flex-wrap: wrap" v-if="machineDetail && machineDetail.length !== 0">
                <div class="machine-oee-dashboard">
                    <b-table-simple responsive outlined id="machine-oee-dashboard"
                        class="machine-oee-dashboard-table scroll gn-table-b">
                        <b-thead head-variant="light">
                            <b-tr>
                                <b-th v-for="header in machineDetailHeader" :rowspan="header.children.length ? 1 : 2"
                                    :colspan="header.children.length ? 2 : 1" :key="header.key" style="width: 6rem">
                                    {{ header.label }}
                                </b-th>
                            </b-tr>
                            <b-tr style="border-bottom: 2px solid #dddddd !important">
                                <b-th v-for="(header, i) in allColsHeader" :key="header.key + i" class="second-tr-th">
                                    {{ header.label }}
                                </b-th>
                            </b-tr>
                        </b-thead>
                        <b-tbody v-if="machineDetail && machineDetail.length !== 0">
                            <b-tr v-for="(rowData, index) in machineDetail" :key="index">
                                <b-td v-for="(innerChild, key) in rowData" :key="key + index"
                                    class="machine-oee-dashboard-label">
                                    <span v-if="key !== 'groupId'">
                                        <span v-if="fieldCheck(key)">
                                            {{ innerChild }}
                                        </span>
                                        <div v-else class="input-div">
                                            <input class="input-f" v-model="rowData[key]" min="0"
                                                :class="{ 'red-border': notValid(innerChild, index, key) }"
                                                @input="changingF(innerChild, index, key)"
                                                @keydown="isInputProper($event, key)" />
                                        </div>
                                    </span>
                                </b-td>
                            </b-tr>
                        </b-tbody>
                        <b-tbody v-if="machineDetail && machineDetail.length === 0">
                            <tr>
                                <td class="text-center" style="height: 380px" rowspan="6"
                                    :colspan="allColsHeader.length + machineDetailHeader.length">
                                    <img alt="no data" src="@/assets/No_Data.svg" />
                                    <div class="ed-date">No Data Found</div>
                                </td>
                            </tr>
                        </b-tbody>
                        <b-tfoot> </b-tfoot>
                    </b-table-simple>
                </div>
                <div class="cml-by-update">
                    <div class="last-ud-by" v-if="updatedByUser && updatedByUserDate && updatedByUserTime">
                        Last updated by {{ updatedByUser }} {{ updatedByUserDate | dateFormater }} , {{ updatedByUserTime }}
                        EST
                    </div>
                </div>
            </div>

            <div v-else>
                <div class="main-con no-fl-con">
                    <img alt="not started yet" src="../../../assets/NoGovStarted.svg" class="no-fl-img" />
                    <div class="no-fl-hd">The Creation of Constraint Working Center is yet to be started.</div>
                    <div class="no-fl-sub"></div>
                </div>
            </div>
        </div>

        <b-modal id="alert-msg" size="lg" ref="msgPopUp" hide-footer :title="headerMessage" :visible="showPopup">
            <div class="row col-12 page-content-regular">
                <p>
                    <span class="page-heading-tab">
                        {{ alertMsg }}
                    </span>
                </p>
            </div>
            <div class="button-div" style="justify-content: right; width: 100%">
                <b-button pill variant="outline-primary" style="padding: 0.25rem 1rem; margin-left: 25rem"
                    class="btn cancel-save-btn button-sm" @click="closeAlertPopup()"> OK </b-button>
            </div>
        </b-modal>
    </div>
</template>
<script>
import "../../../common/css/CommonStyle.css";
import "../../../common/css/font-categories.css";
import Breadcrumb from "../../../components/common/Breadcrumb.vue";
import Loader from "../../../components/common/Loader.vue";
import ApiCalls from "@/common/api/apiCalls";
import Api from "../../../common/constants/api";
import { mapGetters } from "vuex";
import Roles from "@/common/constants/roles";
import moment from "moment";
import MessagePopup from "@/components/common/MessagePopup.vue";
import oidc from '@/common/constants/oidc';

export default {
    name: "OEEDashboard",
    data() {
        return {
            updatedByUser: null,
            updatedByUserDate: null,
            updatedByUserTime: null,
            alertMsg: "",
            showPopup: false,
            showLoader: true,
            editingMode: false,
            headerMessage: "",
            rawData: [],
            willFreezeFor: moment(new Date()).format("MMMM"),
            willFreezeOn: moment(new Date(new Date())).format("MM/DD/YYYY"),
            monthsList: [
                { monthId: 1, monthName: "Jan" },
                { monthId: 2, monthName: "Feb" },
                { monthId: 3, monthName: "Mar" },
                { monthId: 4, monthName: "Apr" },
                { monthId: 5, monthName: "May" },
                { monthId: 6, monthName: "Jun" },
                { monthId: 7, monthName: "Jul" },
                { monthId: 8, monthName: "Aug" },
                { monthId: 9, monthName: "Sep" },
                { monthId: 10, monthName: "Oct" },
                { monthId: 11, monthName: "Nov" },
                { monthId: 12, monthName: "Dec" }
            ],
            plantList: [],
            committedYears: [],
            plantId: 1,
            selectedMonth: new Date().getDate() <= 7 ? Number(new Date().getMonth()) : Number(new Date().getMonth() + 1),
            committedYr: new Date().getFullYear(),
            ribbonConstraint: {},
            machineDetailHeader: [
                {
                    key: "groupName",
                    label: "CONSTRAINTS TO REPLICATE",
                    children: [],
                    sortable: false
                },
                {
                    key: "prevConstraint",
                    label: "PREVIOUS CONSTRAINT",
                    children: [],
                    sortable: false
                },
                {
                    key: "totalConstraintMachines",
                    label: "TOTAL CONSTRAINT MACHINES",
                    children: [],
                    sortable: false,
                    variant: "success"
                },
                {
                    key: "forAllConstraints",
                    label: "FOR ALL CONSTRAINTS",
                    children: [
                        {
                            key: "forAllConstraintsChild1",
                            label: "Total YTD RPL Count"
                        },
                        {
                            key: "forAllConstraintsChild2",
                            label: "Total RPL Percentage"
                        }
                    ],
                    sortable: false,
                    variant: "success"
                },
                {
                    key: "forAllConstraintsPrev",
                    label: `FOR ALL CONSTRAINTS (${new Date().getFullYear()})`,
                    children: [
                        {
                            key: "forAllConstraintsPrevChild1",
                            label: "YTD Completed DEC3/RE2 RPL Count"
                        },
                        {
                            key: "forAllConstraintsPrevChild2",
                            label: "DEC3/RE2 RPL Percentage"
                        }
                    ],
                    sortable: false,
                    variant: "success"
                },
                {
                    key: "forPreviousConstraints",
                    label: "FOR PREVIOUS CONSTRAINTS",
                    children: [
                        {
                            key: "forPreviousConstraintsChild1",
                            label: "Total YTD DEC3/RE5 RPL Count"
                        },
                        {
                            key: "forPreviousConstraintsChild2",
                            label: "Total RPL DEC3/RE5 RPL Percentage"
                        }
                    ],
                    sortable: false,
                    variant: "success"
                },
                {
                    key: "previousConstraints",
                    label: "PREVIOUS CONSTRAINTS",
                    children: [
                        {
                            key: "previousConstraintsChild1",
                            label: "YTD Completed DEC3/RE2 RPL Count"
                        },
                        {
                            key: "previousConstraintsChild2",
                            label: "YTD DEC3/RE2 RPL Percentage"
                        }
                    ],
                    sortable: false,
                    variant: "success"
                },
                {
                    key: "oeeSR",
                    label: "OEE% Schedule Rate",
                    children: [],
                    sortable: false,
                    variant: "success"
                },
                {
                    key: "oeeAR",
                    label: "OEE% Availability Rate",
                    children: [],
                    sortable: false,
                    variant: "success"
                },
                {
                    key: "oeePR",
                    label: "OEE% Performance Rate",
                    children: [],
                    sortable: false,
                    variant: "success"
                },
                {
                    key: "oeeQR",
                    label: "OEE% Quality Rate",
                    children: [],
                    sortable: false,
                    variant: "success"
                },
                {
                    key: "oee1Percent",
                    label: "OEE1%",
                    children: [],
                    sortable: false,
                    variant: "success"
                },
                {
                    key: "oee2Percent",
                    label: "OEE2%",
                    children: [],
                    sortable: false,
                    variant: "success"
                },
                {
                    key: "oee2Baseline",
                    label: "OEE2% Baseline",
                    children: [],
                    sortable: false,
                    variant: "success"
                },
                {
                    key: "oeeGoal",
                    label: "OEE Goal",
                    children: [],
                    sortable: false,
                    variant: "success"
                }
            ],
            allColsHeader: [],
            machineDetail: [],
            machineDetailX: [],
            roles: Roles,
            userPlantId: 0
        };
    },
    components: {
        Breadcrumb,
        Loader,
        MessagePopup
    },
    beforeCreate() {
        this.$store.dispatch("selectedMenu", {
            main: 4,
            subMenu: 1
        });
        this.$store.dispatch("breadcrumb", {
            title: "OEE Dashboard",
            breadcrumb: [
                { name: "Home / ", primary: true, redirect: "/dashboard" },
                { name: "Machine Replication", primary: false }
            ]
        });
    },
    mounted() { },
    created() {
        let day = 7;
        let month = new Date().getMonth() + 1;
        let year = new Date().getFullYear();
        let freezeon = new Date(year, new Date().getDate() <= 7 ? month - 1 : month, day);
        this.willFreezeOn = moment(freezeon).format("DD-MMM-YYYY");
        this.willFreezeFor = new Date().getDate() <= 7 ? moment(new Date(year, new Date().getMonth() - 1, day)).format("MMMM") : moment(new Date()).format("MMMM");
        // Data entries for {{ willFreezeFor }} will freeze on {{ willFreezeOn }}

        this.getCommittedYear();
        this.placeChildElements();
    },
    computed: {
        ...mapGetters(["plantSelectedInAsmtHome", "loggedInUserData", "oeeSelectedYear", "oeeSelectedMonth"]),
        shouldUpdateDisable() {
            if (this.$oidc.userProfile.roles.includes(this.roles.GLOBALPILLARLEADER)) {
                return false
            }
            else {
                let day = 8;
                if (new Date().getDate() < day)
                    return (
                        this.loggedInUserData.plantId !== this.plantId.plantId ||
                        this.committedYr !== new Date().getFullYear() ||
                        !(new Date().getDate() < day && this.selectedMonth === new Date().getMonth())
                    );
                else return this.loggedInUserData.plantId !== this.plantId.plantId || this.selectedMonth !== new Date().getMonth() + 1 || this.committedYr !== new Date().getFullYear();
            }
        }
    },
    methods: {
        onViewBtn() {
            this.$store.dispatch("oeeSelectedMonth", this.selectedMonth);
            this.$store.dispatch("oeeSelectedYear", this.committedYr);
            console.log(this.committedYr);
            this.$router.push("/edit-constraint-machines-group");
        },
        placeChildElements() {
            let elements = [];
            this.machineDetailHeader.forEach((header) => {
                let temp = [];
                if (header.children.length)
                    header.children.forEach((child) => {
                        temp.push({
                            label: child.label,
                            key: child.key
                        });
                    });

                elements = [...elements, ...temp];
            });
            console.log(this.machineDetail);
            this.allColsHeader = elements;
        },
        fieldCheck(key) {
            if (this.editingMode)
                if (
                    key === "forAllConstraintsChild1" ||
                    key === "forAllConstraintsPrevChild1" ||
                    key === "previousConstraintsChild1" ||
                    key === "forPreviousConstraintsChild1" ||
                    key === "oeeSR" ||
                    key === "oeeAR" ||
                    key === "oeePR" ||
                    key === "oeeQR" ||
                    key === "oee2Baseline" ||
                    key === "oeeGoal"
                )
                    return false;
                else return true;
            else return true;
        },
        getCommittedYear() {
            ApiCalls.httpCallDetails(Api.GETOEEDASHBOARDYEAR, "get").then((data) => {
                this.committedYears = data.data.length ? data.data : [new Date().getFullYear()];
                this.getPlantDetails();
            });
        },
        getPlantDetails() {
            ApiCalls.httpCallDetails(Api.GETPLANTLIST, "get").then((data) => {
                this.plantList = data.data;
                let plantId = this.plantSelectedInAsmtHome ? this.plantSelectedInAsmtHome : this.plantList[0].plantId;
                let index = 0;
                if (this.plantSelectedInAsmtHome) index = this.plantList.findIndex((el) => el.plantId == this.plantSelectedInAsmtHome);
                else if (this.loggedInUserData.plantId) {
                    let plantIndex = this.plantList.findIndex((el) => el.plantId == this.loggedInUserData.plantId);
                    if (plantIndex > -1) {
                        index = plantIndex;
                        plantId = this.plantList[index].plantId;
                    }
                } else if (this.loggedInUserData.regionName) {
                    let plantIndex = this.plantList.findIndex((el) => el.regionName == this.loggedInUserData.regionName);
                    if (plantIndex > -1) {
                        index = plantIndex;
                        plantId = this.plantList[index].plantId;
                    }
                }
                this.plantId = this.plantList[index];
                this.$store.dispatch("plantSelectedInAsmtHome", plantId);
                this.filterHandler();
            });
        },
        plantChanged(event) {
            console.log("plantChanged");
            this.showLoader = true;
            this.$store.dispatch("plantSelectedInAsmtHome", event.target.value);
            this.plantId = this.plantList.find((el) => el.plantId == event.target.value);
            this.filterHandler();
        },
        filterHandler() {
            this.$store.dispatch("oeeSelectedMonth", this.selectedMonth);
            this.$store.dispatch("oeeSelectedYear", this.committedYr);
            this.machineDetailHeader[4].label = `FOR ALL CONSTRAINTS (${this.committedYr})`;
            this.showLoader = true;
            const url = Api.GETOEEDASHBOARD + this.plantId.plantId + "/" + this.committedYr + "/" + this.selectedMonth;
            console.log(url);
            this.ribbonConstraint = {};
            ApiCalls.httpCallDetails(url, "get").then((data) => {
                this.machineDetail = [];
                console.log(data);
                this.editingMode = false;
                this.showLoader = false;
                this.updatedByUser = data.data.lastUpdateInfo ? data.data.lastUpdateInfo.lastUpdatedBy : "";
                this.updatedByUserDate = data.data.lastUpdateInfo ? moment(new Date(data.data.lastUpdateInfo.lastUpdatedTime)).format("MM/DD/YYYY") : "";
                this.updatedByUserTime = data.data.lastUpdateInfo ? moment(new Date(data.data.lastUpdateInfo.lastUpdatedTime)).format("LT") : "";

                this.dataFormatterAndSetter(data);
            });
        },
        nullNaNCheck(value) {
            return value ? value : 0;
        },
        nullNaNCheckPercentage(value) {
            return value ? Math.round(value * 100) / 100 : 0;
        },
        dataFormatterAndSetter(data) {
            data.data.data.sort((a, b) => {
                if (a.contstaintType > b.contstaintType) return 1;
                else return a.contstaintType === b.contstaintType ? 0 : -1;
            });
            data.data.data.forEach((row) => {
                this.rawData.push({
                    groupId: row.groupId,
                    groupName: row.constraintGroupName,
                    prevConstraint: row.isPreviousConstraint,
                    totalConstraintMachines: this.nullNaNCheck(row.totalMachines),
                    forAllConstraintsChild1: this.nullNaNCheck(row.ytdrplCount),
                    forAllConstraintsChild2: this.nullNaNCheck(row.ytdrplPercentage),
                    forAllConstraintsPrevChild1: this.nullNaNCheck(row.decrerplCount),
                    forAllConstraintsPrevChild2: this.nullNaNCheck(row.decrerplPercent),
                    forPreviousConstraintsChild1: this.nullNaNCheck(row.preYTDCompletedDECRERPLCount),
                    forPreviousConstraintsChild2: this.nullNaNCheck(row.preYTDCompletedDECRERPLPercent),
                    previousConstraintsChild1: this.nullNaNCheck(row.prevDECRERPLCount),
                    previousConstraintsChild2: this.nullNaNCheck(row.prevDECRERPLPercent),
                    oeeSR: this.nullNaNCheck(row.scheduleRate),
                    oeeAR: this.nullNaNCheck(row.availabilityRate),
                    oeePR: this.nullNaNCheck(row.performanceRate),
                    oeeQR: this.nullNaNCheck(row.qualityRate),
                    oee1Percent: this.nullNaNCheck(row.oeE1),
                    oee2Percent: this.nullNaNCheck(row.oeE2),
                    oee2Baseline: this.nullNaNCheck(row.baseline),
                    oeeGoal: this.nullNaNCheck(row.goal)
                });
                this.ribbonConstraint[row.contstaintType.trim().toLowerCase()] = row.constraintGroupName;
                this.machineDetail.push({
                    groupName: row.constraintGroupName,
                    prevConstraint: row.isPreviousConstraint ? "Y" : "N",
                    totalConstraintMachines: this.nullNaNCheck(row.totalMachines),
                    forAllConstraintsChild1: this.nullNaNCheck(row.ytdrplCount),
                    forAllConstraintsChild2: this.nullNaNCheckPercentage(row.ytdrplPercentage),
                    forAllConstraintsPrevChild1: this.nullNaNCheckPercentage(row.decrerplCount),
                    forAllConstraintsPrevChild2: this.nullNaNCheckPercentage(row.decrerplPercent),
                    forPreviousConstraintsChild1: this.nullNaNCheckPercentage(row.prevDECRERPLCount),
                    forPreviousConstraintsChild2: this.nullNaNCheckPercentage(row.prevDECRERPLPercent),
                    previousConstraintsChild1: this.nullNaNCheckPercentage(row.preYTDCompletedDECRERPLCount),
                    previousConstraintsChild2: this.nullNaNCheckPercentage(row.preYTDCompletedDECRERPLPercent),
                    oeeSR: this.nullNaNCheckPercentage(row.scheduleRate),
                    oeeAR: this.nullNaNCheckPercentage(row.availabilityRate),
                    oeePR: this.nullNaNCheckPercentage(row.performanceRate),
                    oeeQR: this.nullNaNCheckPercentage(row.qualityRate),
                    oee1Percent: this.nullNaNCheckPercentage(row.oeE1),
                    oee2Percent: this.nullNaNCheckPercentage(row.oeE2),
                    oee2Baseline: this.nullNaNCheckPercentage(row.baseline),
                    oeeGoal: this.nullNaNCheckPercentage(row.goal)
                });
            });
        },
        stopEditing() {
            this.editingMode = false;
            this.filterHandler();
        },
        isInputProper(event, key) {
            switch (key) {
                case "forAllConstraintsChild1":
                case "forAllConstraintsPrevChild1":
                case "previousConstraintsChild1":
                case "forPreviousConstraintsChild1":
                    this.isNumber(event);
                    break;
                case "oeeSR":
                case "oeeAR":
                case "oeePR":
                case "oeeQR":
                case "oee2Baseline":
                case "oeeGoal":
                    this.isDecimal(event);
                    break;
            }
        },
        isNumber(evt) {
            let charCode = evt.which ? evt.which : evt.keyCode;
            if (charCode > 31 && isNaN(Number(evt.key)) && charCode !== 46) {
                evt.preventDefault();
            } else {
                return true;
            }
        },
        closeAlertPopup() {
            this.showPopup = false;
        },
        isDecimal(evt) {
            let charCode = evt.which ? evt.which : evt.keyCode;
            console.log(evt, charCode);
            if (charCode === 190) {
                return true;
            }

            if (charCode > 31 && isNaN(Number(evt.key)) && charCode !== 46) {
                evt.preventDefault();
            } else {
                return true;
            }
        },
        changingF(a, pos, keys) {
            console.log("value", a, "index", pos, "key", keys);
            const currentRow = this.machineDetail[pos];
            switch (keys) {
                case "forAllConstraintsChild1":
                    this.machineDetail[pos].forAllConstraintsChild2 = Math.round((currentRow.forAllConstraintsChild1 / currentRow.totalConstraintMachines) * 10000) / 100;
                    break;
                case "forAllConstraintsPrevChild1":
                    this.machineDetail[pos].forAllConstraintsPrevChild2 = Math.round((currentRow.forAllConstraintsPrevChild1 / currentRow.totalConstraintMachines) * 10000) / 100;
                    break;
                case "forPreviousConstraintsChild1":
                    this.machineDetail[pos].forPreviousConstraintsChild2 = Math.round((currentRow.forPreviousConstraintsChild1 / currentRow.totalConstraintMachines) * 10000) / 100;
                    break;
                case "previousConstraintsChild1":
                    this.machineDetail[pos].previousConstraintsChild2 = Math.round((currentRow.previousConstraintsChild1 / currentRow.totalConstraintMachines) * 10000) / 100;
                    break;
                case "oeeSR":
                case "oeeAR":
                case "oeePR":
                case "oeeQR":
                    this.machineDetail[pos].oee1Percent = Math.round(((currentRow.oeeSR * currentRow.oeeAR * currentRow.oeePR * currentRow.oeeQR) / 1000000) * 100) / 100;
                    this.machineDetail[pos].oee2Percent = Math.round(((currentRow.oeeAR * currentRow.oeePR * currentRow.oeeQR) / 10000) * 100) / 100;
                    break;
                case "oee2Baseline":
                    this.machineDetail[pos].oeeGoal = Math.round((Number(currentRow.oee2Baseline) + Number(currentRow.oee2Baseline) * 0.05) * 100) / 100;
                    break;
                default:
                    break;
            }
        },
        isDataValid(req) {
            let isValid = true;
            req.forEach((eachReq, index) => {
                if (
                    !(
                        eachReq.groupId > 0 &&
                        eachReq.ytdrplcount >= 0 &&
                        eachReq.scheduleRate >= 0 &&
                        eachReq.availabilityRate >= 0 &&
                        eachReq.performanceRate >= 0 &&
                        eachReq.qualityRate >= 0 &&
                        eachReq.baseline >= 0 &&
                        eachReq.goal >= 0 &&
                        eachReq.curYTDCompletedDECRECount >= 0 &&
                        eachReq.preYTDDECRERPLCount >= 0 &&
                        eachReq.preYTDCompletedDECRERPLCount >= 0 &&
                        eachReq.scheduleRate <= 300 &&
                        eachReq.availabilityRate <= 300 &&
                        eachReq.performanceRate <= 300 &&
                        eachReq.qualityRate <= 300 &&
                        eachReq.baseline <= 300 &&
                        eachReq.goal <= 300 &&
                        eachReq.ytdrplcount <= this.machineDetail[index].totalConstraintMachines &&
                        eachReq.curYTDCompletedDECRECount <= this.machineDetail[index].totalConstraintMachines &&
                        eachReq.preYTDDECRERPLCount <= this.machineDetail[index].totalConstraintMachines &&
                        eachReq.preYTDCompletedDECRERPLCount <= this.machineDetail[index].totalConstraintMachines
                    )
                ) {
                    isValid = false;
                    this.headerMessage = "Alert";
                    this.alertMsg = "The entered values are invalid. Please enter the correct values.";
                    this.showPopup = true;
                }
            });
            return isValid;
        },
        notValid(a, pos, keys) {
            const currentRow = this.machineDetail[pos];
            switch (keys) {
                case "forAllConstraintsChild1":
                    return currentRow.forAllConstraintsChild1 > currentRow.totalConstraintMachines;
                case "forAllConstraintsPrevChild1":
                    return currentRow.forAllConstraintsPrevChild1 > currentRow.totalConstraintMachines;
                case "forPreviousConstraintsChild1":
                    return currentRow.forPreviousConstraintsChild1 > currentRow.totalConstraintMachines;
                case "previousConstraintsChild1":
                    return currentRow.previousConstraintsChild1 > currentRow.totalConstraintMachines;
                case "oeeSR":
                    return currentRow.oeeSR > 300 || currentRow.oeeSR < 0;
                case "oeeAR":
                    return currentRow.oeeAR > 300 || currentRow.oeeAR < 0;
                case "oeePR":
                    return currentRow.oeePR > 300 || currentRow.oeePR < 0;
                case "oeeQR":
                    return currentRow.oeeQR > 300 || currentRow.oeeQR < 0;
                case "oee2Baseline":
                    return currentRow.oee2Baseline > 300 || currentRow.oee2Baseline < 0;
                case "oeeGoal":
                    return currentRow.oeeGoal > 300 || currentRow.oeeGoal < 0;
                default:
                    return false;
            }
        },
        saveManualData() {
            let reqBody = [];
            this.showLoader = true;
            reqBody = this.machineDetail.map((row, index) => {
                let temp = {
                    commitedYear: Number(this.committedYr),
                    commitedMonth: Number(this.selectedMonth),
                    plantId: Number(this.plantId.plantId),
                    groupId: Number(this.rawData[index].groupId),
                    ytdrplcount: Number(row.forAllConstraintsChild1),
                    scheduleRate: Number(row.oeeSR),
                    availabilityRate: Number(row.oeeAR),
                    performanceRate: Number(row.oeePR),
                    qualityRate: Number(row.oeeQR),
                    baseline: Number(row.oee2Baseline),
                    goal: Number(row.oeeGoal),
                    lastUpdatedBy: this.$oidc.userProfile.email,
                    curYTDCompletedDECRECount: Number(row.forAllConstraintsPrevChild1),
                    preYTDDECRERPLCount: Number(row.forPreviousConstraintsChild1),
                    preYTDCompletedDECRERPLCount: Number(row.previousConstraintsChild1)
                };
                return temp;
            });
            console.log(this.isDataValid(reqBody));
            if (this.isDataValid(reqBody)) {
                ApiCalls.httpCallDetails(Api.SAVEOEEDASHBOARD, "post", reqBody).then((data) => {
                    console.log(data);
                    this.showLoader = false;
                    this.editingMode = false;
                    this.filterHandler();
                });
            } else {
                this.showLoader = false;
            }
        }
    }
};
</script>

<style scoped>
.main-con {
    background: #fff;
    margin-top: 1em;
    border-radius: 6px;
}

input.input-f.red-border,
input.input-f.red-border:hover,
input.input-f.red-border:active,
input.input-f.red-border:focus,
input.input-f.red-border:focus-visible,
input.input-f.red-border:focus-within {
    border-color: red !important;
}

.cml-by {
    width: 80%;
    font-family: "BarlowSb", sans-serif;
    font-size: 1.333rem;
    color: #d35757;
    text-align: right;
}

.cml-by-update {
    width: 96%;
    font-family: "BarlowSb", sans-serif;
    font-size: 1.333rem;
    color: #869ed8;
    text-align: right;
}

.cmp-by-txt {
    margin-left: 0.3rem;
}

.mat-info-con {
    padding: 2rem !important;
    display: flex;
}

.plant-dd {
    width: 20.5%;
}

.mat-status-con {
    margin-left: 2rem;
    display: flex;
    align-items: center;
}

.target-card-lable {
    margin-bottom: 0.5rem;
}

.top-ribbon {
    display: flex;
    justify-content: space-evenly;
    padding: 0 2rem;
    height: 7rem;
    background-color: #eff3ff;
    border: 1px solid #dee0e8;
    align-items: center;
}

.inner-top-ribbon {
    text-align: center;
}

.last-ud-by {
    font-size: 0.916rem;
    font-family: "BarlowR", sans-serif;
    color: #5a5a5a;
    text-align: right;
    padding-right: 2rem;
    padding-bottom: 1rem;
}

.input-div {
    display: flex;
    align-items: center;
    justify-content: center;
    align-content: center;
    /* width: 50px; */
}

.input-f {
    width: 100%;
    max-width: 5rem;
    text-align: center;
    font-size: 1.33rem;
    border-radius: 4px;
    border: #313131 1px solid;
}

.no-fl-con {
    text-align: center;
    padding-top: 8rem;
    padding-bottom: 11rem;
}

.no-fl-hd {
    font-size: 1.166rem !important;
    font-family: "BarlowB", sans-serif !important;
    margin-top: 1rem;
}

.no-fl-sub {
    font-size: 1.166rem !important;
    font-family: "BarlowR", sans-serif !important;
}

.no-fl-img {
    width: 7rem;
}

.machine-oee-dashboard {
    margin-top: 2rem;
    width: 100%;
}

.table-responsive {
    padding-bottom: 1rem;
}

#machine-oee-dashboard th.table-success {
    background-color: #f4f6fd;
}

#machine-oee-dashboard th {
    background-color: #f7f9ff !important;
    font-weight: bold;
}

#machine-oee-dashboard tr td,
#machine-oee-dashboard tr th {
    border: 2px solid #efefef !important;
}

#machine-oee-dashboard tr th:last-child {
    padding: 0 2rem;
}

.machine-oee-dashboard-label {
    text-align: center !important;
    vertical-align: middle !important;
    font-size: 1.333rem;
    font-family: "BarlowM", sans-serif;
    color: #313131 !important;
}

.machine-oee-dashboard-table {
    max-height: 37rem !important;
}

.machine-oee-dashboard-table th div {
    color: #313131 !important;
    font-weight: 100;
}

.second-tr-th {
    text-align: center !important;
    vertical-align: middle !important;
    font-size: 1rem;
    font-family: "BarlowSb", sans-serif;
    color: #313131 !important;
    padding: 0.5rem 2rem;
}

.machine-oee-dashboard-table tbody tr td {
    color: #313131 !important;
    vertical-align: middle;
}

.machine-oee-dashboard-table thead th,
.machine-oee-dashboard-table td {
    text-align: center !important;
    vertical-align: middle !important;
}

#machine-oee-dashboard>.machine-oee-dashboard-table th,
td {
    border: 0px !important;
    border-right: 1px solid #eae6e6 !important;
    border-left: 1px solid #eae6e6 !important;
}

.machine-action-table-machine {
    text-align: left !important;
    font-size: 1.166rem;
    font-family: "BarlowR", sans-serif;
}

/* .machine-oee-dashboard-table th {
    width: 15%;
} */

.machine-oee-dashboard-table td tbody tr td {
    color: #313131 !important;
    vertical-align: middle;
}

.machine-oee-dashboard-table tbody tr:nth-child(even) {
    background-color: #fafafa !important;
}

.machine-oee-dashboard-table .table-light,
.machine-oee-dashboard-table .table-light>td,
.machine-oee-dashboard-table .table-light>th {
    background-color: #f0f0f0 !important;
}

.machine-oee-dashboard-table th {
    color: #313131 !important;
    font-weight: 100;
}

.machine-oee-dashboard-table body tr td {
    color: #313131 !important;
    vertical-align: middle;
}

.machine-oee-dashboard-table tr {
    height: 5rem;
}

.scroll {
    height: auto;
}

.scroll::-webkit-scrollbar {
    width: 9px;
    margin: 1rem 2rem;
    height: 8px !important;
}

.display-flex-center {
    display: flex;
    align-items: center;
    justify-content: center;
    align-content: center;
}

.ontrack {
    width: 2rem;
    background: #3a5824;
    margin: 0 25%;
    text-align: center !important;
    height: 2rem;
    border-radius: 10rem;
    color: #fff !important;
}

.offtrack {
    width: 2rem;
    background: #c11a3b;
    margin: 0 25%;
    height: 2rem;
    text-align: center !important;
    border-radius: 10rem;
    color: #fff !important;
}

.row-month {
    display: flex;
    height: inherit;
    justify-content: center;
    align-items: center;
}

.even-space-vertical-align {
    justify-content: space-between;
    align-items: center;
    display: flex;
}

@media only screen and (max-width: 767px) {
    .mat-info-con {
        display: block !important;
    }

    .plant-dd {
        width: 100% !important;
    }
}
</style>
